const promoCodeList = [
  {
    code: 'JANV24',
    value:
      'Nouvelle Année : Bonus exceptionnel de <br> <b><u>100 CHF offerts</u></b> sur votre première transaction d’un montant = ou > à 3000 CHF ',
    date: 'Offre valable du 20/01/24 au 06/02/24',
  },
  {
    code: 'RE0124',
    value:
      'Nouvelle Année : Bonus exceptionnel de <br> <b><u>100 CHF offerts</u></b> sur votre première transaction d’un montant = ou > à 3000 CHF ',
    date: 'Offre valable du 20/01/24 au 06/02/24',
  },
  {
    code: 'NE0124',
    value:
      'Nouvelle Année : Bonus exceptionnel de <br> <b><u>100 CHF offerts</u></b> sur votre première transaction d’un montant = ou > à 3000 CHF ',
    date: 'Offre valable du 20/01/24 au 06/02/24',
  },
  {
    code: 'S0124M',
    value:
      'Nouvelle Année : Bonus exceptionnel de <br> <b><u>100 CHF offerts</u></b> sur votre première transaction d’un montant = ou > à 3000 CHF ',
    date: 'Offre valable du 20/01/24 au 06/02/24',
  },
  {
    code: 'TK100',
    value:
      'Nouvelle Année : Bonus exceptionnel de <br> <b><u>100 CHF offerts</u></b> sur votre première transaction d’un montant = ou > à 3000 CHF ',
    date: 'Offre valable du 20/01/24 au 06/02/24',
  },
  {
    code: 'GMB100',
    value:
      'Nouvelle Année : Bonus exceptionnel de <br> <b><u>100 CHF offerts</u></b> sur votre première transaction d’un montant = ou > à 3000 CHF ',
    date: 'Offre valable du 20/01/24 au 06/02/24',
  },
  {
    code: 'GTE125',
    value:
      "Offre spéciale pour les Adhérents du GTE <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>en plus des meilleurs taux de change</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 07/02/24 au 31/12/24',
  },
  {
    code: 'CAS125',
    value:
      "Offre spéciale pour les clients du Casino du Lac <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>en plus des meilleurs taux de change</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 1000 CHF</span>",
    date: 'Offre valable du 10/02/24 au 31/12/24',
  },
  {
    code: 'DL125',
    value:
      "Offre spéciale pour les clients de DiLife <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>en plus des meilleurs taux de change</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 1000 CHF</span>",
    date: 'Offre valable du 06/02/2024 au 31/12/2024',
  },
  {
    code: 'MAFL125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'MATK125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'S125M',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'MANE125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'MAGMB125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'MAPB125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'MARE125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'MAGA125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'MAAY125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 20/03/2024 au 30/09/2024',
  },
  {
    code: 'NEW125',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-xxl'>125 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change et de notre programme de fidélité</b> <br> <span class='text-sm'>*25 CHF offerts sur vos 5 premières transactions d’un montant ≥ 3000 CHF</span>",
    date: 'Offre valable du 9/04/2024 au 30/09/2024',
  },
  {
    code: 'ETE50',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-red text-lg'>Dernière chance de profiter des 50 CHF offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change <br> Gagnez jusqu'à 300 CHF/an en récompenses fidélité !</b>",
    date: 'Prolongation exceptionnelle de l’offre jusqu’au 15 / 09 / 2024',
  },
  {
    code: 'GTE25',
    value:
      "Offre Spéciale pour les adhérents du GTE <br> <b class='highlighted-red text-red text-lg'>25 CHF Offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change<br> Gagnez jusqu'à 300 CHF/an en récompenses fidélité !</b>",
    date: 'Offre valable du 01/09/24 au 31/12/24',
  },
  {
    code: 'RUGBY75',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-red text-lg'>75 CHF Offerts*</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change<br> Gagnez jusqu'à 300 CHF/an en récompenses fidélité !</b>",
    date: 'Offre valable du 18/10/24 au 10/11/24',
  },
  {
    code: 'BSC50',
    value:
      "Offre spéciale réservée aux clients de Ben S. S Shop & Change <br> <b class='highlighted-red text-red text-lg'>50 CHF offerts sur votre 1ère transaction</b> <br> <b class='highlighted-red text-lg'>En plus des meilleurs taux de change<br> Gagnez jusqu’à 300 CHF/an en récompenses fidélité !</b>",
    date: 'Offre valable du 15/09/24 au 31/12/24',
  },
  {
    code: 'CORDIA75',
    value:
      "Offre Spéciale pour les nouveaux clients uniquement <br> <b class='highlighted-red text-red text-lg'>75 CHF Offerts*</b> <br> <b class='highlighted-red text-lg'>pour votre 1ère opération d’un montant ≥ 2000 CHF</b>",
    date: 'Offre valable du 20/11/24 au 31/12/24',
  },
];

export default promoCodeList;
